:root {
  /* Tailles par défaut (desktop) */
  --font-size-title-min: 16px;
  --font-size-title-max: 35px;
  --font-size-title-minus-min: 14px;
  --font-size-title-minus-max: 25px;

  /* Tailles adaptatives */
  --font-size-title-fluid: clamp(
    var(--font-size-title-min),
    calc(2rem + 1vw),  /* Ajustement dynamique plus progressif */
    var(--font-size-title-max)
  );

  --font-size-title-minus-fluid: clamp(
    var(--font-size-title-minus-min),
    calc(1.5rem + 0.8vw), /* Ajustement dynamique adapté */
    var(--font-size-title-minus-max)
  );

  --font-color-white: #F4F4F9;
}

@keyframes border-glow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes wood-grain {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  font-family: 'Gotham-Ultra', 'Gotham-Light', Arial, sans-serif;
}

/*HEADER*/

/*ANIMATION BUBBLE*/
/* Conteneur des bulles global */
.bubbles-container {
  position: absolute;
  width: 100%;
  height: calc(100% + 500px); /* Étend l'effet jusqu'au header */
  bottom: 0;
  left: 0;
  z-index: 0; /* Place les bulles entre le background et le contenu */
  pointer-events: none;
}

/* Style des bulles */
.bubble {
  position: absolute;
  bottom: -100px; /* Ajusté pour bien apparaître */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0.6;
  animation: bubble-rise linear infinite;
  z-index: 0;
}

/* Générer plus de bulles avec des tailles et vitesses ajustées */
.bubble:nth-child(1) { left: 2%; width: 30px; height: 30px; animation-duration: 15s; animation-delay: 0s; }
.bubble:nth-child(2) { left: 8%; width: 50px; height: 50px; animation-duration: 18s; animation-delay: 0.1s; }
.bubble:nth-child(3) { left: 15%; width: 40px; height: 40px; animation-duration: 20s; animation-delay: 0.2s; }
.bubble:nth-child(4) { left: 25%; width: 70px; height: 70px; animation-duration: 22s; animation-delay: 0.3s; }
.bubble:nth-child(5) { left: 35%; width: 60px; height: 60px; animation-duration: 19s; animation-delay: 0.4s; }
.bubble:nth-child(6) { left: 45%; width: 30px; height: 30px; animation-duration: 13s; animation-delay: 0.5s; }
.bubble:nth-child(7) { left: 55%; width: 80px; height: 80px; animation-duration: 24s; animation-delay: 0.6s; }
.bubble:nth-child(8) { left: 65%; width: 45px; height: 45px; animation-duration: 16s; animation-delay: 0.7s; }
.bubble:nth-child(9) { left: 75%; width: 90px; height: 90px; animation-duration: 25s; animation-delay: 0,8s; }
.bubble:nth-child(10) { left: 85%; width: 40px; height: 40px; animation-duration: 14s; animation-delay: 0.9s; }
.bubble:nth-child(11) { left: 95%; width: 70px; height: 70px; animation-duration: 21s; animation-delay: 1s; }
.bubble:nth-child(12) { left: 50%; width: 100px; height: 100px; animation-duration: 28s; animation-delay: 0s; }
.bubble:nth-child(13) { left: 70%; width: 55px; height: 55px; animation-duration: 17s; animation-delay: 0.1s; }
.bubble:nth-child(14) { left: 30%; width: 85px; height: 85px; animation-duration: 26s; animation-delay: 0.2s; }
.bubble:nth-child(15) { left: 90%; width: 50px; height: 50px; animation-duration: 23s; animation-delay: 0.3s; }
.bubble:nth-child(16) { left: 5%; width: 25px; height: 25px; animation-duration: 8s; animation-delay: 0.4s; }
.bubble:nth-child(17) { left: 12%; width: 70px; height: 70px; animation-duration: 17s; animation-delay: 0.5s; }
.bubble:nth-child(18) { left: 28%; width: 40px; height: 40px; animation-duration: 13s; animation-delay: 0.6s; }
.bubble:nth-child(19) { left: 38%; width: 55px; height: 55px; animation-duration: 11s; animation-delay: 0.7s; }
.bubble:nth-child(20) { left: 52%; width: 95px; height: 95px; animation-duration: 22s; animation-delay: 0.8s; }
.bubble:nth-child(21) { left: 62%; width: 60px; height: 60px; animation-duration: 15s; animation-delay: 0.9s; }
.bubble:nth-child(22) { left: 77%; width: 45px; height: 45px; animation-duration: 9s; animation-delay: 1s; }
.bubble:nth-child(23) { left: 88%; width: 100px; height: 100px; animation-duration: 23s; animation-delay: 0s; }
.bubble:nth-child(24) { left: 94%; width: 35px; height: 35px; animation-duration: 12s; animation-delay: 0.1s; }
.bubble:nth-child(25) { left: 98%; width: 75px; height: 75px; animation-duration: 18s; animation-delay: 0.2s; }


/* Animation améliorée des bulles */
@keyframes bubble-rise {
  0% {
    transform: translateY(0vh) scale(1);
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-150vh) scale(1.2); /* Ajustement pour disparaître progressivement */
    opacity: 0;
  }
}

header {
  width: 100%;
  max-width: 100vw;
}

.header-contain {
  /*Background ardoise*/
  /*
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: 100% calc(100% - 12vw);
  background-repeat: no-repeat;*/
  position: relative;
  background-color: #019EE3; /* Bleu du bandeau */
  overflow: hidden; /* Évite que les bulles sortent du header */
}

/* 📌 S'assurer que les images passent au-dessus des bulles */
.img-real-france, .logo-francemug, .img-contact, .img-facebook, .img-boutique, .img-etagere, .mug-common, .icones-container, .header-slogan-container,
.caract-icones-container, .text-caract-container {
  position: relative;
  z-index: 2; /* Place les images au-dessus des bulles */
}

.top-header-images {
  display: flex;
  justify-content: space-evenly;
}

.header-navigation {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
}

.contact-follow {
  margin: 0 0 25px 0;
}

.img-real-france {
  width: 100%;
  max-width: 225px;
  aspect-ratio: 225 / 139;
  height: auto;
  object-fit: contain;
}

.logo-title {
  position: relative;
  left: 30px;
  min-width: 150px;
}

.logo-francemug {
  width: 100%;
  max-width: 390px;
  aspect-ratio: 390 / 200;
  height: auto;
  object-fit: contain;
  min-width: 150px;
}

.img-contact {
  width: 100%;
  max-width: 130px;
  aspect-ratio: 130 / 33;
  height: auto;
  object-fit: contain;
}

.img-facebook {
  width: 100%;
  max-width: 154px;
  aspect-ratio: 154 / 33;
  height: auto;
  object-fit: contain;
}

.img-boutique {
  width: 100%;
  max-width: 251px;
  aspect-ratio: 251 / 119;
  height: auto;
  object-fit: contain;
}

.contact-link {
  display: inline-block;
}

.facebook-link {
  padding-left: 23px;
  display: inline-block;
}

.boutique-link {
  display: block;
  position: relative;
  left: 10px;
}

.header-title {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
}

.header-slogan-container {
  font-family: 'Gotham-Ultra';
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  min-height: 150px;
}

.header-slogan {
  margin: 0;
  font-weight: bold;
  text-align: center;
  color: var(--font-color-white);
  line-height: 0px;
  position: relative;
  bottom: 15px;
}

.title2-text {
  font-size: var(--font-size-title-fluid);
}

.title2-minus {
  font-size: var(--font-size-title-minus-fluid);
}

.img-etagere {
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.fade-out {
  animation: fadeOut 0.5s ease-in-out;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.invisible {
  opacity: 0;
  transition: opacity 0.2s; /* Transition pour chevaucher avec fade-in */
}

.mug-common {
  object-fit: contain;
  height: 45.5%;
  position: absolute;
}

.mug1-design {
  top: 34.5%;
  left: 28.89vw;
}

.mug2-design {
  top: 34.5%;
  left: 45.7%;
}

.mug3-design {
  top: 34.5%;
  left: 62%;
}

.caract-mug-container {
  /*Background ardoise*/
  /*
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: cover;
  background-repeat: no-repeat;*/
  position: relative; /* Permet de gérer le positionnement interne */
  background-color: #019EE3; /* Bleu du bandeau */
  overflow: hidden; /* Empêche les bulles de sortir */
  display: flex;
  height: 400px;
  text-align: center;
  padding: 0 15%;
}

.caract-icones-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.8em;
  color: var(--font-color-white);
}

.icones-container {
  width: 30%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.icone-caract {
  width: 55px;
}

.text-caract {
  padding: 0 15px;
}

.title-caract {
  padding: 0 75px;
  margin: 0;
}

.under-caract-container {
  margin-top: 5px;
}

.under-text-caract {
  font-size: 0.9em;
  margin: 0;
}

/* NAV BAR */

.nav-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  min-height: 50px;
  margin: 2% 3%;
}

.cat-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  min-height: 30px;
  padding: 10px 20px;
  margin: auto auto 15px auto;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 35px;
  transition: background-color 0.3s ease;
}

.nav-bar li:hover {
  background-color: #d3d3d3;
}

.nav-bar li.active {
  background-color: #2b2d74;
  color: white;
}


/* MAIN */

main {
  font-family: 'Gotham-Ultra', sans-serif;
  background-image: url('./assets/main/texture_icones.webp');
  background-repeat: repeat;
  min-height: 800px;
  min-width: 100%;
}

.mug-list {
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 70px 5% 0 5%;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Ajuste la hauteur du spinner */
  min-height: 100px;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animated-slide {
  animation: slide-in 0.5s cubic-bezier(0.175, 0.385, 0.12, 1.275);
}

@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.mug-display {
  width: 18%;
  margin: 0 25px 75px 25px;
  font-family: 'Gotham-Light';
}

.mug-card {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.mug-card:hover {
  transform: scale(1.05);
}

.mug-image {
  width: 100%;
  height: auto;
}

.mug-info {
  text-align: center;
}

.mug-title {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  margin: 0;
  text-transform: capitalize;
}

.mug-price {
  color: #2c3e50;
  font-size: 1em;
  font-weight: bold;
}

.filter-bar {
  margin-bottom: 20px;
}

.filter-bar button {
  margin-right: 10px;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
}

.filter-bar button:hover {
  background-color: #2980b9;
}

.mug-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.principal-mug-img {
  object-fit: contain;
  width: 10%;
}

.main-media {
  cursor: pointer;
}

.main-media img,
.main-media video {
  width: 100%;
  max-width: 400px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.price-link-contain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-amazon {
  /*
  font-weight: bold;
  color: #FF0000;
  margin: 0;
  border: 1px solid;
  padding: 7px;
  border-radius: 50%;
  background-color: #fffeff;
  */
  text-indent: -9999px;
  overflow: hidden;
}

.link-amazon {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.button-amazon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 50px;
  border-radius: 35px;
  background: #231F20;
  color: #fffeff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  z-index: 0;
  font-weight: bold;
  border: 2px outset #fffeff;
  padding: 5px 0;
}

.button-amazon::before {
  content: '';
  position: absolute;
  top: 0;
  left: -150%; /* Commence hors du bouton */
  width: 300%; /* Largeur large pour couvrir tout le bouton */
  height: 100%;
  border-radius: 15px;
  border: 3px solid transparent;
  background-image: linear-gradient(120deg, transparent, #fff, transparent);
  z-index: 1;
  transition: none; /* Pas de transition sur l'état initial */
  opacity: 0;
}

.button-amazon:hover::before {
  opacity: 1;
  animation: border-glow 1s linear forwards; /* Animation se produit une seule fois */
}

.text-button-amazon {
  font-family: 'Gotham-Light';
  font-size: 1em;
}

.img-logo-amazon {
  object-fit: contain;
  width: 40%;
}

.thumbnails {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.thumbnail:hover {
  transform: scale(1.1);
}

.video-thumbnail-container {
  position: relative;
  display: inline-block;
}

.video-thumbnail {
  display: block;
  height: auto;
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 20px solid grey; /* Triangle */
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  opacity: 0.8;
}

/* LIGHTBOX */

.lightbox-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.lightbox-media {
  max-width: 500px;
  max-height: 500px;
}

.lightbox-image {
  max-width: 100%;
  max-height: 100%;
}

.lightbox-video {
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto; /* Permet de capturer les événements, mais uniquement pour la vidéo */
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 36px;
  color: #fffeff;
  cursor: pointer;
  z-index: 1001;
}

.lightbox-close:hover {
  color: #FF0000;
}

.close-btn {
  pointer-events: all; /* Assure que le clic est capturé par la croix même pour la vidéo */
}

.lightbox-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  z-index: 1001;
}

.lightbox-prev,
.lightbox-next {
  font-size: 55px;
  color: #fffeff;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.lightbox-prev:hover,
.lightbox-next:hover {
  color: #fde581;
}

.lightbox-video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.lightbox-video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

.lightbox-video::-webkit-media-controls-panel {
  width: calc(100% - 25px); /* Pour compenser la suppression du bouton plein écran */
}

.lightbox-video::-moz-media-controls-fullscreen-button {
  display: none;
}

.lightbox-video::backdrop {
  display: none; /* Empêche également le plein écran en mode non-Chrome */
}



/*FOOTER*/

.footer-comm {
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: cover;
  background-repeat: no-repeat;
}

.title-comm {
  margin: 0;
  padding: 75px 0 20px 0;
  text-align: center;
  font-family: 'Gotham-Ultra';
  font-size: 2em;
  color: #fde581;
}

.slogan-comm {
  color: #fffeff;
  text-align: center;
  font-size: 1.7em;
  margin: 0;
  padding-bottom: 35px;
  font-family: 'Gotham-Light';
  font-weight: bold;
  line-height: 1;
}

.slogan-text-comm {
  margin: 0;
}

.text-comm-container {
  display: flex;
  justify-content: center;
  font-family: 'Gotham-Light';
  color: #fffeff;
  padding-bottom: 70px;
  min-height: 150px;
}

.under-comm-container {
  box-sizing: border-box;
  margin: 0 30px;
  width: 30%;
  min-height: 150px;
}

.text-comm-top {
  padding-top: 21px;
}

.footer-link {
  background-image: url('./assets/main/texture-ardoise-brosse.webp');
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 0.7em;
  color: #fffeff;
  height: 200px;
}

.global-link-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
}

.mentions-container {
  display: flex;
  align-items: center;
}

.text-link {
  text-decoration: none;
  color: #fffeff;
  padding: 0 3px;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.text-link:hover {
  color: #fde581;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

/*Résolution Tablette*/
@media screen and (max-width: 1024px) {

  :root {
    --font-size-title-min: 14px;
    --font-size-title-max: 30px;
    --font-size-title-minus-min: 12px;
    --font-size-title-minus-max: 22px;
  }

  /*HEADER*/

  /*ANIMATION BUBBLE*/
/* Conteneur des bulles global */
.bubbles-container {
  position: absolute;
  width: 100%;
  height: calc(100% + 500px); /* Étend l'effet jusqu'au header */
  bottom: 0;
  left: 0;
  z-index: 0; /* Place les bulles entre le background et le contenu */
  pointer-events: none;
}

/* Style des bulles */
.bubble {
  position: absolute;
  bottom: -100px; /* Ajusté pour bien apparaître */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0.6;
  animation: bubble-rise linear infinite;
  z-index: 0;
}

/* Générer plus de bulles avec des tailles et vitesses ajustées */
.bubble:nth-child(1) { left: 2%; width: 30px; height: 30px; animation-duration: 15s; animation-delay: 1s; }
.bubble:nth-child(2) { left: 8%; width: 50px; height: 50px; animation-duration: 18s; animation-delay: 2s; }
.bubble:nth-child(3) { left: 15%; width: 40px; height: 40px; animation-duration: 20s; animation-delay: 1.5s; }
.bubble:nth-child(4) { left: 25%; width: 70px; height: 70px; animation-duration: 22s; animation-delay: 2.5s; }
.bubble:nth-child(5) { left: 35%; width: 60px; height: 60px; animation-duration: 19s; animation-delay: 3s; }
.bubble:nth-child(6) { left: 45%; width: 30px; height: 30px; animation-duration: 13s; animation-delay: 2s; }
.bubble:nth-child(7) { left: 55%; width: 80px; height: 80px; animation-duration: 24s; animation-delay: 3.5s; }
.bubble:nth-child(8) { left: 65%; width: 45px; height: 45px; animation-duration: 16s; animation-delay: 2s; }
.bubble:nth-child(9) { left: 75%; width: 90px; height: 90px; animation-duration: 25s; animation-delay: 2s; }
.bubble:nth-child(10) { left: 85%; width: 40px; height: 40px; animation-duration: 14s; animation-delay: 1s; }
.bubble:nth-child(11) { left: 95%; width: 70px; height: 70px; animation-duration: 21s; animation-delay: 1.8s; }
.bubble:nth-child(12) { left: 50%; width: 100px; height: 100px; animation-duration: 28s; animation-delay: 0.5s; }
.bubble:nth-child(13) { left: 70%; width: 55px; height: 55px; animation-duration: 17s; animation-delay: 3s; }
.bubble:nth-child(14) { left: 30%; width: 85px; height: 85px; animation-duration: 26s; animation-delay: 2s; }
.bubble:nth-child(15) { left: 90%; width: 50px; height: 50px; animation-duration: 23s; animation-delay: 2.5s; }
.bubble:nth-child(16) { left: 5%; width: 25px; height: 25px; animation-duration: 8s; animation-delay: 2.2s; }
.bubble:nth-child(17) { left: 12%; width: 70px; height: 70px; animation-duration: 17s; animation-delay: 1.8s; }
.bubble:nth-child(18) { left: 28%; width: 40px; height: 40px; animation-duration: 13s; animation-delay: 3s; }
.bubble:nth-child(19) { left: 38%; width: 55px; height: 55px; animation-duration: 11s; animation-delay: 2.4s; }
.bubble:nth-child(20) { left: 52%; width: 95px; height: 95px; animation-duration: 22s; animation-delay: 0.8s; }
.bubble:nth-child(21) { left: 62%; width: 60px; height: 60px; animation-duration: 15s; animation-delay: 1.5s; }
.bubble:nth-child(22) { left: 77%; width: 45px; height: 45px; animation-duration: 9s; animation-delay: 2.8s; }
.bubble:nth-child(23) { left: 88%; width: 100px; height: 100px; animation-duration: 23s; animation-delay: 1s; }
.bubble:nth-child(24) { left: 94%; width: 35px; height: 35px; animation-duration: 12s; animation-delay: 2s; }
.bubble:nth-child(25) { left: 98%; width: 75px; height: 75px; animation-duration: 18s; animation-delay: 3s; }


/* Animation améliorée des bulles */
@keyframes bubble-rise {
  0% {
    transform: translateY(100vh) scale(1);
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-150vh) scale(1.2); /* Ajustement pour disparaître progressivement */
    opacity: 0;
  }
}

  header {
    width: 100%;
    max-width: 100vw;
  }

  .header-contain {
    /*Background ardoise*/
    /*
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: 100% calc(100% - 12vw);
    background-repeat: no-repeat;*/
    position: relative;
    background-color: #019EE3; /* Bleu du bandeau */
    overflow: hidden; /* Évite que les bulles sortent du header */
  }
  

  .top-header-images {
    display: flex;
    justify-content: space-evenly;
  }

  .header-navigation {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
  }

  .contact-follow {
    margin: 0 0 25px 0;
  }

  .img-real-france {
    width: 100%;
    max-width: 225px;
    aspect-ratio: 225 / 139;
    height: auto;
    object-fit: contain;
  }

  .logo-title {
    position: relative;
    left: 30px;
    min-width: 150px;
  }

  .logo-francemug {
    width: 100%;
    max-width: 390px;
    aspect-ratio: 390 / 200;
    height: auto;
    object-fit: contain;
    min-width: 150px;
  }

  .img-contact {
    width: 100%;
    max-width: 130px;
    aspect-ratio: 130 / 33;
    height: auto;
    object-fit: contain;
  }

  .img-facebook {
    width: 100%;
    max-width: 154px;
    aspect-ratio: 154 / 33;
    height: auto;
    object-fit: contain;
  }

  .img-boutique {
    width: 100%;
    max-width: 251px;
    aspect-ratio: 251 / 119;
    height: auto;
    object-fit: contain;
  }

  .contact-link {
    display: inline-block;
  }

  .facebook-link {
    padding-left: 23px;
    display: inline-block;
  }

  .boutique-link {
    display: block;
    position: relative;
    left: 10px;
  }

  .header-title {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    border: 0;
  }

  .header-slogan-container {
    font-family: 'Gotham-Ultra';
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    min-height: 150px;
  }

  .header-slogan {
    margin: 0;
    font-weight: bold;
    text-align: center;
    color: var(--font-color-white);
    line-height: 0px;
    position: relative;
    bottom: 15px;
  }

  .title2-text {
    font-size: var(--font-size-title-fluid);
  }
  
  .title2-minus {
    font-size: var(--font-size-title-minus-fluid);
  }

  .img-etagere {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .fade-out {
    animation: fadeOut 0.5s ease-in-out;
  }

  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  .invisible {
    opacity: 0;
    transition: opacity 0.2s; /* Transition pour chevaucher avec fade-in */
  }

  .mug-common {
    object-fit: contain;
    height: 45.5%;
    position: absolute;
  }

  .mug1-design {
    top: 34.5%;
    left: 28.89vw;
  }

  .mug2-design {
    top: 34.5%;
    left: 45.7%;
  }

  .mug3-design {
    top: 34.5%;
    left: 62%;
  }

  .caract-mug-container {
    /*Background ardoise*/
    /*
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;*/
    position: relative; /* Permet de gérer le positionnement interne */
    background-color: #019EE3; /* Bleu du bandeau */
    overflow: hidden; /* Empêche les bulles de sortir */
    display: flex;
    height: 400px;
    text-align: center;
    padding: 0 15%;
  }

  .caract-icones-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    color: var(--font-color-white);
  }

  .icones-container {
    width: 30%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .icone-caract {
    width: 55px;
  }

  .text-caract {
    padding: 0 15px;
  }

  .title-caract {
    padding: 0 75px;
    margin: 0;
  }

  .under-caract-container {
    margin-top: 5px;
  }

  .under-text-caract {
    font-size: 0.9em;
    margin: 0;
  }

  .footer-comm {
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .title-comm {
    margin: 0;
    padding: 75px 0 20px 0;
    text-align: center;
    font-family: 'Gotham-Ultra';
    font-size: 2em;
    color: #fde581;
  }

  .slogan-comm {
    color: #fffeff;
    text-align: center;
    font-size: 1.7em;
    margin: 0;
    padding-bottom: 35px;
    font-family: 'Gotham-Light';
    font-weight: bold;
    line-height: 1;
  }

  .slogan-text-comm {
    margin: 0;
  }

  .text-comm-container {
    display: flex;
    justify-content: center;
    font-family: 'Gotham-Light';
    color: #fffeff;
    padding-bottom: 70px;
    min-height: 150px;
  }

  .under-comm-container {
    box-sizing: border-box;
    margin: 0 30px;
    width: 30%;
    min-height: 150px;
  }

  .text-comm-top {
    padding-top: 21px;
  }

  /* NAV BAR */

  .nav-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    min-height: 50px;
    margin: 2% 3%;
  }

  .cat-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 30px;
    padding: 10px 20px;
    margin: auto auto 15px auto;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 35px;
    transition: background-color 0.3s ease;
  }

  .nav-bar li:hover {
    background-color: #d3d3d3;
  }

  .nav-bar li.active {
    background-color: #2b2d74;
    color: white;
  }


  /* MAIN */

  main {
    font-family: 'Gotham-Ultra', sans-serif;
    background-image: url('./assets/main/texture_icones.webp');
    background-repeat: repeat;
    min-height: 800px;
    min-width: 100%;
  }

  .mug-list {
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 70px 5% 0 5%;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Ajuste la hauteur du spinner */
    min-height: 100px;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .animated-slide {
    animation: slide-in 0.5s cubic-bezier(0.175, 0.385, 0.12, 1.275);
  }

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /*
  main {
    position: relative;
    width: 100%;
    min-height: 100vh; 
    background: linear-gradient(120deg, #D3D3D3 25%, #A7A7A7 75%);
    background-size: 200% 200%;
    overflow: hidden;  
  }

  .bubbles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .bubble {
    position: absolute;
    bottom: -100px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: bubble-rise 20s infinite ease-in-out;
    opacity: 0.6;
  }

  .bubble:nth-child(1) {
    left: 10%;
    width: 60px;
    height: 60px;
    animation-duration: 25s;
    animation-delay: 1s;
  }

  .bubble:nth-child(2) {
    left: 25%;
    width: 30px;
    height: 30px;
    animation-duration: 20s;
    animation-delay: 3s;
  }

  .bubble:nth-child(3) {
    left: 40%;
    width: 50px;
    height: 50px;
    animation-duration: 30s;
    animation-delay: 5s;
  }

  .bubble:nth-child(4) {
    left: 55%;
    width: 80px;
    height: 80px;
    animation-duration: 20s;
    animation-delay: 2s;
  }

  .bubble:nth-child(5) {
    left: 70%;
    width: 40px;
    height: 40px;
    animation-duration: 15s;
    animation-delay: 4s;
  }

  .bubble:nth-child(6) {
    left: 85%;
    width: 70px;
    height: 70px;
    animation-duration: 18s;
    animation-delay: 6s;
  }

  @keyframes bubble-rise {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(-900vh) scale(1.2); 
        opacity: 0;
    }
  }*/

  .mug-display {
    width: 18%;
    margin: 0 25px 75px 25px;
    font-family: 'Gotham-Light';
  }

  .mug-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .mug-card:hover {
    transform: scale(1.05);
  }

  .mug-image {
    width: 100%;
    height: auto;
  }

  .mug-info {
    text-align: center;
  }

  .mug-title {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
  }

  .mug-price {
    color: #2c3e50;
    font-size: 1em;
    font-weight: bold;
  }

  .filter-bar {
    margin-bottom: 20px;
  }

  .filter-bar button {
    margin-right: 10px;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
  }

  .filter-bar button:hover {
    background-color: #2980b9;
  }

  .mug-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .principal-mug-img {
    object-fit: contain;
    width: 10%;
  }

  .main-media {
    cursor: pointer;
  }

  .main-media img,
  .main-media video {
    width: 100%;
    max-width: 400px;
    margin-bottom: 5px;
    border-radius: 8px;
  }

  .price-link-contain {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-amazon {
    /*
    font-weight: bold;
    color: #FF0000;
    margin: 0;
    border: 1px solid;
    padding: 7px;
    border-radius: 50%;
    background-color: #fffeff;
    */
    text-indent: -9999px;
    overflow: hidden;
  }

  .link-amazon {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .button-amazon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 50px;
    border-radius: 35px;
    background: #231F20;
    color: #fffeff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 0;
    font-weight: bold;
    border: 2px outset #fffeff;
    padding: 5px 0;
  }

  .button-amazon::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%; /* Commence hors du bouton */
    width: 300%; /* Largeur large pour couvrir tout le bouton */
    height: 100%;
    border-radius: 15px;
    border: 3px solid transparent;
    background-image: linear-gradient(120deg, transparent, #fff, transparent);
    z-index: 1;
    transition: none; /* Pas de transition sur l'état initial */
    opacity: 0;
  }

  .button-amazon:hover::before {
    opacity: 1;
    animation: border-glow 1s linear forwards; /* Animation se produit une seule fois */
  }

  .text-button-amazon {
    font-family: 'Gotham-Light';
    font-size: 1em;
  }

  .img-logo-amazon {
    object-fit: contain;
    width: 40%;
  }

  .thumbnails {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .thumbnail:hover {
    transform: scale(1.1);
  }

  .video-thumbnail-container {
    position: relative;
    display: inline-block;
  }

  .video-thumbnail {
    display: block;
    height: auto;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 20px solid grey; /* Triangle */
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    opacity: 0.8;
  }

  /* LIGHTBOX */

  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }

  .lightbox-media {
    max-width: 500px;
    max-height: 500px;
  }

  .lightbox-image {
    max-width: 100%;
    max-height: 100%;
  }

  .lightbox-video {
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto; /* Permet de capturer les événements, mais uniquement pour la vidéo */
  }

  .lightbox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 36px;
    color: #fffeff;
    cursor: pointer;
    z-index: 1001;
  }

  .lightbox-close:hover {
    color: #FF0000;
  }

  .close-btn {
    pointer-events: all; /* Assure que le clic est capturé par la croix même pour la vidéo */
  }

  .lightbox-navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1001;
  }

  .lightbox-prev,
  .lightbox-next {
    font-size: 55px;
    color: #fffeff;
    cursor: pointer;
    user-select: none;
    padding: 5px;
  }

  .lightbox-prev:hover,
  .lightbox-next:hover {
    color: #fde581;
  }

  .lightbox-video::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  .lightbox-video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  .lightbox-video::-webkit-media-controls-panel {
    width: calc(100% - 25px); /* Pour compenser la suppression du bouton plein écran */
  }

  .lightbox-video::-moz-media-controls-fullscreen-button {
    display: none;
  }

  .lightbox-video::backdrop {
    display: none; /* Empêche également le plein écran en mode non-Chrome */
  }



  /*FOOTER*/

  .footer-link {
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 0.7em;
    color: #fffeff;
    height: 200px;
  }

  .global-link-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
  }

  .mentions-container {
    display: flex;
    align-items: center;
  }

  .text-link {
    text-decoration: none;
    color: #fffeff;
    padding: 0 3px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .text-link:hover {
    color: #fde581;
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
}

/*Résolution Smartphone*/
@media screen and (max-width: 768px) {

  :root {
    --font-size-title-min: 12px;
    --font-size-title-max: 20px;
    --font-size-title-minus-min: 10px;
    --font-size-title-minus-max: 16x;
  }

  /*HEADER*/

  /*ANIMATION BUBBLE*/
/* Conteneur des bulles global */
.bubbles-container {
  position: absolute;
  width: 100%;
  height: calc(100% + 500px); /* Étend l'effet jusqu'au header */
  bottom: 0;
  left: 0;
  z-index: 0; /* Place les bulles entre le background et le contenu */
  pointer-events: none;
}

/* Style des bulles */
.bubble {
  position: absolute;
  bottom: -100px; /* Ajusté pour bien apparaître */
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: 0.6;
  animation: bubble-rise linear infinite;
  z-index: 0;
}

/* Générer plus de bulles avec des tailles et vitesses ajustées */
.bubble:nth-child(1) { left: 2%; width: 30px; height: 30px; animation-duration: 15s; animation-delay: 1s; }
.bubble:nth-child(2) { left: 8%; width: 50px; height: 50px; animation-duration: 18s; animation-delay: 2s; }
.bubble:nth-child(3) { left: 15%; width: 40px; height: 40px; animation-duration: 20s; animation-delay: 1.5s; }
.bubble:nth-child(4) { left: 25%; width: 70px; height: 70px; animation-duration: 22s; animation-delay: 2.5s; }
.bubble:nth-child(5) { left: 35%; width: 60px; height: 60px; animation-duration: 19s; animation-delay: 3s; }
.bubble:nth-child(6) { left: 45%; width: 30px; height: 30px; animation-duration: 13s; animation-delay: 2s; }
.bubble:nth-child(7) { left: 55%; width: 80px; height: 80px; animation-duration: 24s; animation-delay: 3.5s; }
.bubble:nth-child(8) { left: 65%; width: 45px; height: 45px; animation-duration: 16s; animation-delay: 2s; }
.bubble:nth-child(9) { left: 75%; width: 90px; height: 90px; animation-duration: 25s; animation-delay: 2s; }
.bubble:nth-child(10) { left: 85%; width: 40px; height: 40px; animation-duration: 14s; animation-delay: 1s; }
.bubble:nth-child(11) { left: 95%; width: 70px; height: 70px; animation-duration: 21s; animation-delay: 1.8s; }
.bubble:nth-child(12) { left: 50%; width: 100px; height: 100px; animation-duration: 28s; animation-delay: 0.5s; }
.bubble:nth-child(13) { left: 70%; width: 55px; height: 55px; animation-duration: 17s; animation-delay: 3s; }
.bubble:nth-child(14) { left: 30%; width: 85px; height: 85px; animation-duration: 26s; animation-delay: 2s; }
.bubble:nth-child(15) { left: 90%; width: 50px; height: 50px; animation-duration: 23s; animation-delay: 2.5s; }
.bubble:nth-child(16) { left: 5%; width: 25px; height: 25px; animation-duration: 8s; animation-delay: 2.2s; }
.bubble:nth-child(17) { left: 12%; width: 70px; height: 70px; animation-duration: 17s; animation-delay: 1.8s; }
.bubble:nth-child(18) { left: 28%; width: 40px; height: 40px; animation-duration: 13s; animation-delay: 3s; }
.bubble:nth-child(19) { left: 38%; width: 55px; height: 55px; animation-duration: 11s; animation-delay: 2.4s; }
.bubble:nth-child(20) { left: 52%; width: 95px; height: 95px; animation-duration: 22s; animation-delay: 0.8s; }
.bubble:nth-child(21) { left: 62%; width: 60px; height: 60px; animation-duration: 15s; animation-delay: 1.5s; }
.bubble:nth-child(22) { left: 77%; width: 45px; height: 45px; animation-duration: 9s; animation-delay: 2.8s; }
.bubble:nth-child(23) { left: 88%; width: 100px; height: 100px; animation-duration: 23s; animation-delay: 1s; }
.bubble:nth-child(24) { left: 94%; width: 35px; height: 35px; animation-duration: 12s; animation-delay: 2s; }
.bubble:nth-child(25) { left: 98%; width: 75px; height: 75px; animation-duration: 18s; animation-delay: 3s; }


/* Animation améliorée des bulles */
@keyframes bubble-rise {
  0% {
    transform: translateY(100vh) scale(1);
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(-150vh) scale(1.2); /* Ajustement pour disparaître progressivement */
    opacity: 0;
  }
}

  header {
    width: 100%;
    max-width: 100vw;
  }

  .header-contain {
    /*Background ardoise*/
    /*
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: 100% calc(100% - 12vw);
    background-repeat: no-repeat;*/
    position: relative;
    background-color: #019EE3; /* Bleu du bandeau */
    overflow: hidden; /* Évite que les bulles sortent du header */
  }
  

  .top-header-images {
    display: flex;
    justify-content: space-evenly;
  }

  .header-navigation {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
  }

  .contact-follow {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin: 10px 10px 5px 0;
  }

  .img-real-france {
    width: 100%;
    max-width: 140px;
    aspect-ratio: 225 / 139;
    height: auto;
    object-fit: contain;
  }

  .logo-title {
    position: relative;
    left: 0;
    min-width: 150px;
  }

  .logo-francemug {
    width: 100%;
    max-width: 240px;
    aspect-ratio: 390 / 200;
    height: auto;
    object-fit: contain;
    min-width: 150px;
  }

  .img-contact {
    width: 100%;
    max-width: 90px;
    aspect-ratio: 130 / 33;
    height: auto;
    object-fit: contain;
  }

  .img-facebook {
    width: 100%;
    max-width: 110px;
    aspect-ratio: 154 / 33;
    height: auto;
    object-fit: contain;
  }

  .img-boutique {
    width: 100%;
    max-width: 251px;
    aspect-ratio: 251 / 119;
    height: auto;
    object-fit: contain;
  }

  .contact-link {
    display: inline-block;
  }

  .facebook-link {
    padding-left: 23px;
    display: inline-block;
  }

  .boutique-link {
    display: block;
    position: relative;
    left: 10px;
  }

  .header-title {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    white-space: nowrap;
    border: 0;
  }

  .header-slogan-container {
    font-family: 'Gotham-Ultra';
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin-top: 1.5vh;
  }

  .header-slogan {
    margin: 0;
    font-weight: bold;
    text-align: center;
    color: var(--font-color-white);
    line-height: 0px;
    position: relative;
    bottom: 15px;
  }

  .title2-text {
    font-size: var(--font-size-title-fluid);
  }
  
  .title2-minus {
    font-size: var(--font-size-title-minus-fluid);
  }

  .etagere-contain {
    margin-top: 3vh;
  }

  .img-etagere {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .fade-out {
    animation: fadeOut 0.5s ease-in-out;
  }

  .fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  .invisible {
    opacity: 0;
    transition: opacity 0.2s; /* Transition pour chevaucher avec fade-in */
  }

  .mug-common {
    object-fit: contain;
    height: 45.5%;
    position: absolute;
  }

  .mug1-design {
    top: 34.5%;
    left: 28.89vw;
  }

  .mug2-design {
    top: 34.5%;
    left: 45.7%;
  }

  .mug3-design {
    top: 34.5%;
    left: 62%;
  }

  .caract-mug-container {
    /*Background ardoise*/
    /*
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;*/
    position: relative; /* Permet de gérer le positionnement interne */
    background-color: #019EE3; /* Bleu du bandeau */
    overflow: hidden; /* Empêche les bulles de sortir */
    display: flex;
    height: 400px;
    text-align: center;
    padding: 0 15%;
  }

  .caract-icones-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    color: var(--font-color-white);
  }

  .icones-container {
    width: 30%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

  .icone-caract {
    width: 55px;
  }

  .text-caract {
    padding: 0 15px;
  }

  .title-caract {
    padding: 0 75px;
    margin: 0;
  }

  .under-caract-container {
    margin-top: 5px;
  }

  .under-text-caract {
    font-size: 0.9em;
    margin: 0;
  }

  .footer-comm {
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .title-comm {
    margin: 0;
    padding: 75px 0 20px 0;
    text-align: center;
    font-family: 'Gotham-Ultra';
    font-size: 2em;
    color: #fde581;
  }

  .slogan-comm {
    color: #fffeff;
    text-align: center;
    font-size: 1.7em;
    margin: 0;
    padding-bottom: 35px;
    font-family: 'Gotham-Light';
    font-weight: bold;
    line-height: 1;
  }

  .slogan-text-comm {
    margin: 0;
  }

  .text-comm-container {
    display: flex;
    justify-content: center;
    font-family: 'Gotham-Light';
    color: #fffeff;
    padding-bottom: 70px;
    min-height: 150px;
  }

  .under-comm-container {
    box-sizing: border-box;
    margin: 0 30px;
    width: 30%;
    min-height: 150px;
  }

  .text-comm-top {
    padding-top: 21px;
  }

  /* NAV BAR */

  .nav-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    min-height: 50px;
    margin: 2% 3%;
  }

  .cat-button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 30px;
    padding: 10px 20px;
    margin: auto auto 15px auto;
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 35px;
    transition: background-color 0.3s ease;
  }

  .nav-bar li:hover {
    background-color: #d3d3d3;
  }

  .nav-bar li.active {
    background-color: #2b2d74;
    color: white;
  }


  /* MAIN */

  main {
    font-family: 'Gotham-Ultra', sans-serif;
    background-image: url('./assets/main/texture_icones.webp');
    background-repeat: repeat;
    min-height: 800px;
    min-width: 100%;
  }

  .mug-list {
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 70px 5% 0 5%;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Ajuste la hauteur du spinner */
    min-height: 100px;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .animated-slide {
    animation: slide-in 0.5s cubic-bezier(0.175, 0.385, 0.12, 1.275);
  }

  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  /*
  main {
    position: relative;
    width: 100%;
    min-height: 100vh; 
    background: linear-gradient(120deg, #D3D3D3 25%, #A7A7A7 75%);
    background-size: 200% 200%;
    overflow: hidden;  
  }

  .bubbles {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .bubble {
    position: absolute;
    bottom: -100px;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: bubble-rise 20s infinite ease-in-out;
    opacity: 0.6;
  }

  .bubble:nth-child(1) {
    left: 10%;
    width: 60px;
    height: 60px;
    animation-duration: 25s;
    animation-delay: 1s;
  }

  .bubble:nth-child(2) {
    left: 25%;
    width: 30px;
    height: 30px;
    animation-duration: 20s;
    animation-delay: 3s;
  }

  .bubble:nth-child(3) {
    left: 40%;
    width: 50px;
    height: 50px;
    animation-duration: 30s;
    animation-delay: 5s;
  }

  .bubble:nth-child(4) {
    left: 55%;
    width: 80px;
    height: 80px;
    animation-duration: 20s;
    animation-delay: 2s;
  }

  .bubble:nth-child(5) {
    left: 70%;
    width: 40px;
    height: 40px;
    animation-duration: 15s;
    animation-delay: 4s;
  }

  .bubble:nth-child(6) {
    left: 85%;
    width: 70px;
    height: 70px;
    animation-duration: 18s;
    animation-delay: 6s;
  }

  @keyframes bubble-rise {
    0% {
        transform: translateY(0) scale(1);
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: translateY(-900vh) scale(1.2); 
        opacity: 0;
    }
  }*/

  .mug-display {
    width: 18%;
    margin: 0 25px 75px 25px;
    font-family: 'Gotham-Light';
  }

  .mug-card {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }

  .mug-card:hover {
    transform: scale(1.05);
  }

  .mug-image {
    width: 100%;
    height: auto;
  }

  .mug-info {
    text-align: center;
  }

  .mug-title {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
  }

  .mug-price {
    color: #2c3e50;
    font-size: 1em;
    font-weight: bold;
  }

  .filter-bar {
    margin-bottom: 20px;
  }

  .filter-bar button {
    margin-right: 10px;
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
  }

  .filter-bar button:hover {
    background-color: #2980b9;
  }

  .mug-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .principal-mug-img {
    object-fit: contain;
    width: 10%;
  }

  .main-media {
    cursor: pointer;
  }

  .main-media img,
  .main-media video {
    width: 100%;
    max-width: 400px;
    margin-bottom: 5px;
    border-radius: 8px;
  }

  .price-link-contain {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .price-amazon {
    /*
    font-weight: bold;
    color: #FF0000;
    margin: 0;
    border: 1px solid;
    padding: 7px;
    border-radius: 50%;
    background-color: #fffeff;
    */
    text-indent: -9999px;
    overflow: hidden;
  }

  .link-amazon {
    display: flex;
    justify-content: center;
    text-decoration: none;
  }

  .button-amazon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 50px;
    border-radius: 35px;
    background: #231F20;
    color: #fffeff;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 0;
    font-weight: bold;
    border: 2px outset #fffeff;
    padding: 5px 0;
  }

  .button-amazon::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%; /* Commence hors du bouton */
    width: 300%; /* Largeur large pour couvrir tout le bouton */
    height: 100%;
    border-radius: 15px;
    border: 3px solid transparent;
    background-image: linear-gradient(120deg, transparent, #fff, transparent);
    z-index: 1;
    transition: none; /* Pas de transition sur l'état initial */
    opacity: 0;
  }

  .button-amazon:hover::before {
    opacity: 1;
    animation: border-glow 1s linear forwards; /* Animation se produit une seule fois */
  }

  .text-button-amazon {
    font-family: 'Gotham-Light';
    font-size: 1em;
  }

  .img-logo-amazon {
    object-fit: contain;
    width: 40%;
  }

  .thumbnails {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .thumbnail:hover {
    transform: scale(1.1);
  }

  .video-thumbnail-container {
    position: relative;
    display: inline-block;
  }

  .video-thumbnail {
    display: block;
    height: auto;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-left: 20px solid grey; /* Triangle */
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    opacity: 0.8;
  }

  /* LIGHTBOX */

  .lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
  }

  .lightbox-media {
    max-width: 500px;
    max-height: 500px;
  }

  .lightbox-image {
    max-width: 100%;
    max-height: 100%;
  }

  .lightbox-video {
    max-width: 100%;
    max-height: 100%;
    pointer-events: auto; /* Permet de capturer les événements, mais uniquement pour la vidéo */
  }

  .lightbox-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 36px;
    color: #fffeff;
    cursor: pointer;
    z-index: 1001;
  }

  .lightbox-close:hover {
    color: #FF0000;
  }

  .close-btn {
    pointer-events: all; /* Assure que le clic est capturé par la croix même pour la vidéo */
  }

  .lightbox-navigation {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    z-index: 1001;
  }

  .lightbox-prev,
  .lightbox-next {
    font-size: 55px;
    color: #fffeff;
    cursor: pointer;
    user-select: none;
    padding: 5px;
  }

  .lightbox-prev:hover,
  .lightbox-next:hover {
    color: #fde581;
  }

  .lightbox-video::-webkit-media-controls-fullscreen-button {
    display: none;
  }

  .lightbox-video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  .lightbox-video::-webkit-media-controls-panel {
    width: calc(100% - 25px); /* Pour compenser la suppression du bouton plein écran */
  }

  .lightbox-video::-moz-media-controls-fullscreen-button {
    display: none;
  }

  .lightbox-video::backdrop {
    display: none; /* Empêche également le plein écran en mode non-Chrome */
  }



  /*FOOTER*/


  .footer-link {
    background-image: url('./assets/main/texture-ardoise-brosse.webp');
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 0.7em;
    color: #fffeff;
    height: 200px;
  }

  .global-link-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
  }

  .mentions-container {
    display: flex;
    align-items: center;
  }

  .text-link {
    text-decoration: none;
    color: #fffeff;
    padding: 0 3px;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }

  .text-link:hover {
    color: #fde581;
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
}